/*novas interfaces dos inputs*/

input[type=checkbox].ace-switch + .lbl::before, input[type=checkbox].ace-switch + label::before {
    border: 0;
}

input[type=checkbox].ace-switch:checked + .lbl::before, input[type=checkbox].ace-switch:checked + label::before {
    height: 18px;
    border-radius: 10px;
    line-height: 18px;
}

input[type=checkbox]:checked + .lbl::before, input[type=checkbox]:checked + label::before, input[type=checkbox]:checked {
    background: #005dff;
    color: #ffffff;
    border: 0;
    width: 14px;
    height: 14px;
    padding: 1px;
    border-radius: 4px;
}

input[type=checkbox] + .lbl::before, input[type=checkbox] + label::before, input[type=checkbox] {
    background-color: #ffffff;
    border: 1px solid #cacaca;
    height: 14px;
    width: 14px;
    min-width: 14px;
    border-radius: 4px;
}

input[type=radio] {
    opacity: 1;
    margin: 0;
}

input[type=radio]:checked:before {
    background: #005DFF;
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    position: absolute;
}

input[type=radio]:checked:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #005DFF;
    display: block;
    position: absolute;
    border-radius: 100%;
    border: 2px solid #fff;
    margin: 2px;
}

input[type=radio]:not(:checked):after {
    background: #ffffff;
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    border: 2px solid #6C6C6C;
    position: absolute;
}


.btn {
    border-radius: 2px;
    border: 0;
    text-shadow: none !important;
}

/*fim dos novos layouts dos inputs*/

input[disabled], select[disabled], textarea[disabled], input[readonly], select[readonly], textarea[readonly] {
    background-color: #eee !important;
}

.select2-container {
    margin-bottom: 10px;
}

.select2-container .select2-choice {
    height: 28px;
}

/* Origins - Custom Dimensions Dialog */
.fields-dropdown {
    padding: 0;
    width: 250px;
}

.fields-list-container {
    max-height: 250px;
    overflow: auto;
    border-bottom: 1px solid #CCC;
}

.fields-list {
    margin: 0;
    padding: 5px;
}

.fields-list li {
    padding: 0 0 3px 0;
}

.btn-group > .default-border.dropdown-toggle {
    border-radius: 4px;
}

.checkbox-parent input[type=checkbox] + label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    cursor: pointer !important;
}

.checkbox-parent input[type=checkbox]:disabled + label {
    cursor: default !important;
}

/* END */

/* Dashboard Filters Component */
.map-legacy-with-filters,
.dashboard-wrapper-with-filters.filter-top-fixed {
    padding-top: 43px;
}

.filter-container.filter-expand {
    border-bottom: 0;
    padding: 9px;
    min-height: 28px;
    max-height: 28px;
    overflow: hidden;
}

.dashboard-filter-box .filter-block > .label:first-child {
    margin: 0;
}

.dashboard-filter-information.filter-block.filter-required a {
    border-radius: 4px;
}

.dashboard-filter-information.filter-block.filtered a {
    border-radius: 4px 0px 0px 4px;
}

.dashboard-filter-information.filter-block.filtered a.remove-filter {
    margin-left: -5px;
    margin-right: 0;
    border-radius: 4px;
}

/*ref: https://github.com/sol7/bi-machine/issues/5192*/
.dashboard-filter-box {
    z-index: 6 !important;
}

/* start filter-bottom-fixed */
.dashboard-filter-box.filter-bottom-fixed {
    position: fixed;
    bottom: -10px;
    left: 50px;
    width: Calc(100% - 50px);
    height: 51px;
    z-index: 3;
}

.dashboard-filter-box.filter-bottom-fixed .filter-container {
    bottom: 4px;
    padding: 8px;
    /*background: #f7f8fa;*/
    border-top: 1px solid #e4e4e4;
    border-radius: 0;
    box-shadow: none;
}

.dashboard-filter-box.filter-bottom-fixed .dashboard-filter-icon-expand {
    display: none;
}

.dashboard-filter-box.filter-bottom-fixed .dashboard-filter-save {
    right: 0;
}

.dashboard-wrapper-with-filters.edit-mode .dashboard-filter-box.filter-bottom-fixed .dashboard-filter-save {
    right: 48px;
}

.filter-bottom-fixed .dashboard-filter-container-persistence {
    padding-right: 40px;
}

.filter-bottom-fixed .dashboard-filter-container-persistence.dashboard-filter-container-persistence-save-disabled {
    padding-right: 30px;
}

.dashboard-wrapper-with-filters.edit-mode .filter-bottom-fixed .dashboard-filter-container-persistence {
    padding-right: 80px;
}

/* end filter-bottom-fixed */

/* start filter-top-fixed */
.dashboard-filter-box.filter-top-fixed {
    z-index: 3;
    position: fixed;
    width: Calc(100% - 52px);
    left: 50px;
    top: 83px;
}

.dashboard-filter-box.filter-top-fixed .filter-container {
    box-shadow: none;
}

.dashboard-filter-box.filter-top-fixed .filter-container {
    margin: 0;
    border-radius: 0;
}

.dashboard-filter-box.filter-top-fixed .dashboard-filter-icon-expand {
    display: none;
}

.dashboard-filter-box.filter-top-fixed .dashboard-filter-save {
    right: 2px;
    position: fixed;
    top: 83px;
    transition: top var(--expand-header-transition);
    z-index: 10;
}

.cockpit-header-hidden .dashboard-filter-box.filter-top-fixed .dashboard-filter-save {
    top: 36px;
}

.dashboard-wrapper-with-filters.filter-top-right.edit-mode .dashboard-filter-box.filter-top-right {
    right: 60px;
    max-width: Calc(100% - 140px);
}

.dashboard-filter-information.filter-block.filtered {
    background: #575bd8;
}

.dashboard-filter-information.filterDragging.filtered .label {
    max-width: 160px !important;
    overflow: hidden;
}

.no-scroll {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    height: 100vh !important;
}

.dashboard-wrapper-with-filters.edit-mode .dashboard-filter-box.filter-top-fixed .dashboard-filter-save {
    right: 48px;
}

.dashboard-filter-box.filter-top-fixed .filter-block > .label:first-child {
    margin: 0;
}

.dashboard-filter-box.filter-top-fixed .filter-container {
    padding: 8px 6px 6px;
    border-bottom: 1px solid #e4e4e4;
}

.dashboard-filter-information.filter-block.filtered a {
    background: #575bd8 !important;
    color: #fff !important;
    line-height: 18px;
}

.dashboard-filter-box.filter-top-fixed .filter-container .label {
    padding: 4px 6px 4px 6px;
}

.dashboard-filter-box.filter-top-fixed .filter-container .label  {
    border: 0;
}

.filter-top-fixed .dashboard-filter-container-persistence {
    padding-right: 40px;
}

.filter-top-fixed .dashboard-filter-container-persistence.dashboard-filter-edit-mode {
    padding-right: 40px;
}

.filter-top-fixed .dashboard-filter-container-persistence.dashboard-filter-container-persistence-save-disabled {
    padding-right: 30px;
}

.dashboard-wrapper-with-filters.edit-mode .filter-top-fixed .dashboard-filter-container-persistence {
    padding-right: 80px;
}

/* end filter-top-fixed */


/* start filter-top-right */
.dashboard-filter-box.filter-top-right {
    position: absolute;
    top: 100px;
    right: 80px;
    z-index: 9;
    max-width: Calc(100% - 150px);
}

.dashboard-filter-box.filter-top-right .filter-container.filter-expand {
    padding-right: 64px;
}

/* end filter-top-right */


/* start filter-top-left */
.dashboard-filter-box.filter-top-left {
    position: absolute;
    top: 100px;
    left: 75px;
    z-index: 9;
    max-width: Calc(100% - 150px);
}

.dashboard-filter-box.filter-top-left .dashboard-filter-save {
    left: 36px;
}

.dashboard-filter-box.filter-top-left .dashboard-filter-icon-expand {
    left: 0;
}

.cockpit-item-panel .dashboard-filter-box.filter-bottom-left .dashboard-filter-container-persistence.dashboard-filter-container-persistence-save-disabled {
    padding-left: 0;
}

.dashboard-filter-box.filter-top-left .dashboard-filter-container-persistence.dashboard-filter-container-persistence-save-disabled {
    padding-left: 0;
}

.dashboard-filter-box.filter-top-left .dashboard-filter-container-persistence.dashboard-filter-container-persistence-save-enable {
    padding-left: 72px;
    padding-right: 0;
}

.dashboard-filter-box.filter-top-left.contracted .filter-container.filter-expand {
    display: none;
}

.dashboard-filter-box.filter-top-left.contracted .filter-container.filter-contracted {
    display: block;
}

.dashboard-filter-box.filter-top-left .filter-container.filter-contracted {
    float: left;
}

.dashboard-filter-box.filter-top-left i.icon.icon-bim-menuexpand:before,
.dashboard-filter-box.filter-top-left i.icon.icon-bim-menucontracted:before {
    transform: rotate(180deg);
}

/* end filter-top-left */

.dashboard-filter-box.filter-bottom-left i.icon.icon-bim-menucontracted:before,
.dashboard-filter-box.filter-bottom-left i.icon.icon-bim-menuexpand:before {
    transform: rotate(180deg);
}

/* start filter-bottom-right */
.dashboard-filter-box.filter-bottom-right {
    position: absolute;
    z-index: 3;
    max-width: Calc(100% - 140px);
    bottom: 5px;
    right: 20px;
}

.dashboard-filter-box.filter-bottom-right .dashboard-filter-container-persistence.dashboard-filter-container-persistence-save-disabled {
    margin-right: 30px;
}

.dashboard-wrapper-with-filters.edit-mode .dashboard-filter-box.filter-bottom-right {
    right: 60px;
}

.filter-bottom-right.edit-mode .dashboard-filter-box.filter-bottom-right {
    right: 60px;
}

.dashboard-filter-box.filter-bottom-right.contracted .filter-container.filter-expand {
    display: none;
}

.dashboard-filter-box.filter-bottom-right.contracted .filter-container.filter-contracted {
    display: block;
}

/* end filter-bottom-right */

/* start filter-bottom-left */
.dashboard-filter-box.filter-bottom-left {
    position: absolute;
    z-index: 3;
    max-width: Calc(100% - 150px);
    bottom: 5px;
    left: 80px;
}

.cockpit-item-panel .dashboard-filter-box.filter-bottom-left {
    max-width: Calc(100% - 50px);
    left: 20px;
}

.dashboard-filter-box.filter-bottom-left .dashboard-filter-container-persistence {
    margin-left: 36px;
}

.dashboard-filter-box.filter-bottom-left.contracted {
    width: 44px;
}

.dashboard-filter-box.filter-bottom-left.contracted .filter-container.filter-expand {
    display: none;
}

.dashboard-filter-box.filter-bottom-left.contracted .filter-container.filter-contracted {
    display: block;
}

.dashboard-filter-box.filter-bottom-left .dashboard-filter-save {
    left: 34px;
}

.dashboard-filter-box.filter-bottom-left .dashboard-filter-icon-expand {
    left: 0;
}

.dashboard-filter-box.filter-bottom-left .dashboard-filter-container-persistence.dashboard-filter-container-persistence-save-enable {
    padding-left: 33px;
}

/* end filter-bottom-left */


/* contract-expand filter */
.dashboard-filter-box.filter-top-right.contracted .filter-container.filter-expand {
    display: none;
}

.dashboard-filter-box.filter-top-right.contracted .filter-container.filter-contracted {
    display: block;
}

.filter-container.filter-contracted {
    height: 36px;
    width: 36px;
}

/* end contract-expand filter */

.dashboard-filter-box.filter-top-left .dashboard-filter-icon-expand i.icon-bim-menuexpand,
.dashboard-filter-box.filter-top-left .dashboard-filter-icon-expand i.icon-bim-menucontracted,
.dashboard-filter-box.filter-bottom-left .dashboard-filter-icon-expand i.icon-bim-menuexpand,
.dashboard-filter-box.filter-bottom-left .dashboard-filter-icon-expand i.icon-bim-menucontracted {
    margin-top: -2px;
    position: absolute;
}

.filter-container {
    background: #ffffff;
    padding: 5px;
    margin: 0px 0px 14px 0px;
    border-radius: 4px;
    box-shadow: inset 0 1px 0 0 #EFEFEF, 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.dashboard-filter-information i.icon-filter, .dashboard-filter-information i.icon-bim-filter {
    position: absolute;
    top: 4px;
}

.dashboard-filter-container-persistence {
    white-space: nowrap;
    padding-right: 15px;
}

.dashboard-filter-information-description {
    margin-left: 5px;
}

.dashboard-filter-icon-expand {
    max-width: 25px;
    height: 25px;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 11px 8px 10px 10px;
    font-size: 25px;
    color: #888;
    z-index: 2;
}

.dashboard-filter-save {
    max-width: 34px;
    position: absolute;
    right: 34px;
    top: -1px;
    padding: 6px 4px;
    z-index: 20;
}

.dashboard-filter-save button:hover {
    background-color: white !important;
    color: #404040 !important;
}

.btn-save-filter {
    height: 32px;
    width: 34px;
    padding: 4px;
}

.btn-save-filter .material-icons {
    font-size: 18px;
    text-shadow: none !important;
    padding: 4px;
    align-self: center;
}

.dashboard-filter-information {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-right: 4px;
    z-index: 10;
}

.dashboard-filter-information.disabled {
    opacity: 0.5;
}

.dashboard-filter-information.filtered {
    border: 1px solid #575bd8;
    background: #575bd8;
    max-height: 26px;
}

.dashboard-filter-information .filtered i.icon-filter, .dashboard-filter-information .filtered i.icon-bim-filter {
    color: #2876fd;
}

.dashboard-filter-information a:focus {
    outline: 0;
}

.filter-container-bar {
    padding: 4px 0;
}

.filter-top-fixed .filter-container-bar, .filter-bottom-fixed .filter-container-bar {
    padding-left: 2px;
}

.nodis .dashboard-filter-icon {
    font-size: 18px;
    margin-right: 5px;
}

.dashboard-filter-information.filter-block.filtered span.dashboard-filter-information-icon i.icon-bim-filter {
    color: #ffffff !important;
}

.dashboard-filter-information.filter-information-edit-mode {
    padding: 0 40px 0 0;
}

.dashboard-filter-information.filter-information-edit-mode-explorer {
    padding: 0 20px 0 0;
}

.filter-container.filter-contracted {
    display: none;
}

.filter-container .label {
    background: #FFFFFF !important;
    color: #464646 !important;
    padding: 2px 10px 4px 6px;
    text-shadow: none !important;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .5px;
}

.CockpitNavFilterDrop .filter-container .label {
    padding: 6px 14px 6px 6px;
}

.filter-container .label.expand-filter,
.filter-container .label.remove-filter {
    color: rgba(0, 0, 0, .6) !important;
}

.filter-container .label.filtered {
    color: rgba(0, 0, 0, .6) !important;
    border-right: 0;
}

.filter-container .label.disabled {
    background-color: #aaa !important;
    cursor: not-allowed;
    color: #ffffff !important;
}


/* filters on cockpit adjust */

.cockpit-view .dashboard-filter-box.filter-top-fixed .filter-container,
.cockpit-item-panel .dashboard-filter-box.filter-top-fixed .filter-container {
    left: 0;
    position: fixed;
    top: 84px;
    width: 100%;
    border-top: 1px solid #e4e4e4;
}

.cockpit-view .dashboard-filter-box.filter-top-fixed .filter-container,
.cockpit-item-panel .dashboard-filter-box.filter-top-fixed .filter-container,
.cockpit-item-panel .dashboard-filter-box.filter-top-left,
.cockpit-item-panel .dashboard-filter-box.filter-top-right {
    transition: top var(--expand-header-transition);
}

.cockpit-header-hidden .cockpit-view .dashboard-filter-box.filter-top-fixed .filter-container,
.cockpit-header-hidden .cockpit-item-panel .dashboard-filter-box.filter-top-fixed .filter-container {
    top: 36px;
}

.cockpit-header-hidden .cockpit-item-panel .dashboard-filter-box.filter-top-left,
.cockpit-header-hidden .cockpit-item-panel .dashboard-filter-box.filter-top-right {
    top: 52px;
}

.CockpitNavFilterDrop .dashboard-filter-information i.icon-bim-filter {
    top: 8px;
}

.dashboard-filter-box.filter-top-fixed {
    height: 0;
}

.cockpit-view .dashboard-filter-box.filter-top-right .filter-container.filter-expand,
.cockpit-item-panel .dashboard-filter-box.filter-top-right .filter-container.filter-expand {
    padding-right: 32px;
    margin: 0;
}

.filter-top-right.edit-mode .dashboard-filter-box.filter-top-right {
    right: 60px;
}

.dashboard-filter-box.filter-top-right {
    right: 20px;
    max-width: Calc(100% - 100px);
}

.cockpit-view .dashboard-filter-box.filter-top-left .filter-container.filter-expand,
.cockpit-item-panel .dashboard-filter-box.filter-top-left .filter-container.filter-expand {
    padding-right: 0;
    padding-left: 46px;
}

.cockpit-view .dashboard-filter-box.filter-top-left,
.cockpit-item-panel .dashboard-filter-box.filter-top-left {
    left: 20px;
    max-width: Calc(100% - 50px);
}

.cockpit-view .dashboard-filter-box.filter-bottom-right,
.cockpit-item-panel .dashboard-filter-box.filter-bottom-right {
    right: 20px;
    max-width: Calc(100% - 50px);
}

.cockpit-view .dashboard-filter-box.filter-bottom-fixed,
.cockpit-item-panel .dashboard-filter-box.filter-bottom-fixed {
    width: 100%;
    left: 0;
}

/* end filters on cockpit adjust */


/* TODO: Fix to dynamic truncate elements text based on percentage width */
input[type="text"].select-items-component-search-input {
    width: 100%;
    padding: 2px 5px;
    margin: 0 0 4px 0;
    border-radius: 4px 0 0 4px !important;
}

input[type="text"].select-items-component-search-input.buttons {
    width: calc(100% - 20px);
}

.select-items-component-items-container {
    width: 394px;
    height: 250px;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.DialogJsf .select-items-one .select-items-component-items-container {
    width: 850px;
}

.select-items-one .select-items-component-items-container {
    width: 100%;
}

.span6 .select-items-one .select-items-component-items-container {
    width: 315px;
}

.select-items-one .lbl.iceSelOneRb {
    width: 530px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.radio span.lbl {
    margin-left: 22px;
}

.span6 .select-items-one .lbl.iceSelOneRb {
    width: 240px;
}

.select-items-search-group {
    width: calc(100% - 42px);
    text-align: right;
    margin-bottom: 0;
}

.select-items-search-button {
    height: 26px;
}

.select-items-search-button > i {
    margin: 0 !important;
}

.select-items-many > .span6 {
    width: calc(48.93617021276595% - 22px);
}

.select-items-many > .span1 {
    width: 25px;
    margin-top: 12%;
}

.select-items-many > .controls > a {
    display: block;
    height: 35px;
}

.select-items-component-controls-size {
    font-size: 18px;
}

/* END */

.dropdown-navbar > li.dropdown-select-one > [class*="icon-"] {
    margin-left: 8px;
    margin-right: 2px !important;
}

.dropdown-select-one > .input-append {
    margin-bottom: 2px;
    margin-top: 2px;
}

.dropdown-select-one select {
    width: 140px;
    padding: 2px;
}

.dropdown-select-one .add-on {
    width: 25px;
    height: 24px;
    padding-left: 5px;
}

.dropdown-select-one .add-on > [class*="icon-"],
.dropdown-select-one .add-on > [class*="icon-"]:hover {
    color: white
}

.centralized-loading-image {
    position: absolute;
    /* Graceful degradation */
    top: 49%;
    left: 49%;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
}

.ace-nav > li.user_menu {
    background: #FAFAFA;
    background: -moz-linear-gradient(top, #FFFFFF 0%, #F2F2F2 37%, #FAFAFA 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #F2F2F2 37%, #FAFAFA 100%);
    background: linear-gradient(to bottom, #FFFFFF, #F2F2F2) repeat scroll 0 0 #FAFAFA !important;
}

div.loading-submenu {
    border: none !important;
    display: block !important;
    background: url("../images/spinner.gif") no-repeat;
    width: 26px;
    height: 26px;
    padding: 3px;
    position: absolute;
    right: 10px;
    top: 9px;
    background-size: 20px;
}

/* Fix Pagination inside Wizard */
.step-content .pagination-list.unstyled .active {
    display: inline;
}

/* Fix Presentation Left Margin */
.presentation-content #page-content-projects {
    margin-left: 0;
}

.zopim {
    display: none !important;
}

/* Select Many */
.iceSelOneRb td {
    font-weight: bolder;
    display: block;
    line-height: 30px;
    font-size: 14px;
    color: #555;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    text-decoration: underline;
    float: left;
}

.two-column-panel-grid .iceSelOneRb tr td {
    width: auto;
}

.input-with-info-icon-container {
    padding-right: 25px;
    position: relative
}

.input-with-info-icon {
    position: absolute;
    top: 5px;
    right: 0;
    font-size: 20px
}

.sweet-alert {
    box-shadow: 0 0 10px;
}

.upload-component {
    position: relative;
    overflow: hidden;
    margin: 10px;
}

.upload-component input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.toolbox-separator {
    float: left;
    border-right: 1px solid #ccc;
    margin-right: 5px !important;
}

.toolbox-separator:before {
    content: "\00A0";
}

button.btn.iceCmdBtn.toolbox-button.disabled + i.material-icons {
    color: rgba(0, 0, 0, .4);
}

button.btn.iceCmdBtn.toolbox-button {
    padding: 0;
    height: 24px;
    width: 24px;
    background: transparent !important;
    cursor: pointer;
}

button.btn.iceCmdBtn.toolbox-button + i.material-icons {
    position: relative;
    top: -24px;
}

.toolbox-button {
    font-size: 16px;
    color: rgba(0, 0, 0, .75);
}

.toolbox-button.mobile {
    font-size: 18px;
}

.toolbox-button.disabled {
    color: #999999;
}

.toolbox-button:hover {
    color: #000000 !important;
}

.component-gallery {
    background: white;
}

.component-gallery > .header {
    padding: 3px 10px;
    color: #fffffd;
    background: #222222;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 14px;
    text-align: left;
}

.component-gallery > .subheader {
    padding: 3px 0 3px 15px;;
    color: #333;
    background: #f5f5f5;
    border-bottom: 1px solid #ebebeb;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
}

.component-gallery > .items {
    padding: 5px;
}

.component-gallery > .items .item {
    width: 60px;
    padding: 8px 0;
    display: inline-block;
}

.component-gallery > .items .item .cpt-item-view-more-icon {
    text-align: center;
    color: #333333;
}

.component-gallery > .items .item .cpt-item-view-more-name {
    text-align: center;
    margin: 5px 0 0 0;
    height: auto;
}

.mdx-table tbody tr.highlight-row td {
    background-color: #696969 !important;
    color: #f5f5f5 !important;
}

.mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #555555 !important;
    color: #f5f5f5 !important;
}

.publisher-fragment-icon {
    color: #08C;
    vertical-align: super;
    margin-right: 5px;
}

.publisher-item.publisher-item-title .publisher-fragment-text {
    font-weight: 500;
}

.publisher-fragment-text {
    display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #555555 !important;
    letter-spacing: .4px;
}

.toggle-support-button {
    position: absolute;
    right: 15px;
    top: 8px;
}

.modal.olap-monitor {
    width: 900px;
    margin-left: -450px
}

.modal.olap-monitor .modal-body {
    min-height: 500px;
    max-height: 500px;
}

.modal.olap-monitor h4 {
    margin: 0;
}

.modal.olap-monitor .close {
    font-size: 24px;
    margin: 0;
}

.olap-monitor-dialog-trigger {
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 3px;
    top: 5px;
    z-index: 16777271;
}

.sweet-alert {
    z-index: 16777271 !important;
}

.kpi-main-container-left {
    text-align: left;
}

.kpi-main-container {
    position: relative;
    vertical-align: middle;
}

.kpi-main-vertical .infobox-content {
    white-space: nowrap !important;
    width: 100% !important;
}

.modal.filter-selection-dialog {
    width: 700px;
    margin-left: -300px;
}

.pagination.pagination-mini > .pagination {
    margin: 0;
}

.select-items-component-items-container .radio,
.select-items-component-items-container .checkbox {
    padding-left: 0;
}

.mdx-table {
    transform: scale3d(1, 1, 1);
}

.clusterize-headers {
    position: relative;
    overflow: hidden;
}

.pivot-table {
    position: relative;
    clear: both;
    overflow: hidden;
}

.clusterize-scroll {
    position: relative;
}

.mdx-table.cols {
    position: relative;
    left: 0;
    top: 0;
    display: block;
}

.mdx-table.with-fixed-cols {
    left: 0;
    top: 0;
    display: block;
}

.pivot-table div.fixed-columns {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    border-right: 1px solid #d3d3d3;
}

.fixed-header-cells-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    margin-left: 0;
    overflow: hidden;
}

.div_content_js_sub .mdx-table {
    width: 100%;
}


.cle-enhanced-editor {
    border: none !important;
}

.add-filter-margin .dataTables_filter label {
    margin-right: 40px;
}

.add-filter-margin .dataTables_filter label input {
    margin-left: 5px;
}

.icePnlPop.dialog.ui-draggable {
    cursor: auto !important;
}

.ui-draggable .dialogBody > .widget-box > .widget-header {
    cursor: move;
    background: #ffffff;
    color: #555555;
}

.icePnlPopBody.dialogBody .widget-box {
    margin: 0;
}

.notification-list-content {
    max-height: 100px;
    overflow: hidden;
}

.btn-orange {
    background-color: #FF7F27 !important;
}

.btn-orange:disabled {
    background-color: #ffb78a !important;
}

.btn-orange:not(:disabled):hover {
    background-color: #D96B21 !important;
    transition: background-position .1s linear;
}

.dropdown-menu li a.menu_sttings {
    width: 100% !important;
    cursor: pointer !important;
    font-weight: 500 !important;
    letter-spacing: .4px;
}

.dropdown-menu li a.menu_sttings:visited,
.dropdown-menu li a.menu_sttings:active,
.dropdown-menu li a.menu_sttings:focus {
    background: inherit !important;
    border: none !important;
    outline: none;
}

.required-profile-dialog {
    width: 800px;
    font-size: 12px;
    word-break: normal;
    text-align: justify;
}

.tree-fragment-selected {
    border: 1px dashed #c1bfbb;
    background-color: #f5f3ef;
    padding: 5px 0 10px !important;
}

.tree-fragment-selected .move-selection-button,
.move-selection-button.selected-parent {
    visibility: hidden;
}

.move-selection-button.selected {
    visibility: visible;
    color: grey;
}

.icon-sigma:before {
    content: "\03A3";
}

.large-tab {
    width: 100px;
    text-align: center;
}

.tab-large-icon {
    font-size: 32px;
    margin: 5px;
}

.tab-large-text {
    font-size: 11px;
    word-break: normal;
}

.qtip-hint {
    font-size: 15px;
    color: #333;
    cursor: pointer;
}

.dialog.maximize {
    top: 0 !important;
    left: 0 !important;
}

.dialog.maximize > table,
.dialog.maximize > table td,
.dialog.maximize > table td .widget-box {
    height: 100% !important;
}

.dialog.maximize .widget-box {
    height: 99.5vh !important;
}

.dialog.maximize > table td .widget-box > .widget-body > .widget-main > .dialog-body {
    height: 100%;
}

.in-memory-form-container {
    min-width: 930px;
    min-height: 480px;
    height: 100%;
    overflow: hidden;
}

.dialog.maximize .struct-field {
    width: 99% !important;
}

.btn-blue {
    background-color: whitesmoke !important;
    color: #0088cc !important;
    border-color: #0088cc !important;
}

.btn-blue:hover:not(:disabled) {
    background-color: #118fd3 !important;
    color: whitesmoke !important;
    border-color: #007ec2 !important;
}

.btn-blue[disabled] {
    background-color: whitesmoke !important;
}

.small-header .crud-table th {
    padding: 2px 8px !important;
}

.icon-spin {
    -moz-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.wizard-small .widget-main {
    padding-top: 0;
    padding-bottom: 0;
}

.wizard-small hr {
    margin: 10px 0;
}

.wizard-small .nav-tabs li a {
    font-size: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.margin-input {
    font-size: 13px;
}

.margin-input input {
    width: 40px;
    margin: 0 2px 0 10px;
}

.csv-data-preview {
    overflow: auto;
    border: 1px solid #ccc;
    max-width: 95vw;
}

.icon-on-leftttt [class^="icon-"] {
    position: absolute;
    left: 5px;
    top: 5px;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.upload-component-btn {
    padding: 6px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    cursor: pointer !important;
}

.upload-component-file-name-holder {
    width: 175px !important;
    padding: 2px 4px 3px !important;
    margin: 0 !important;
}

.pagination ul > li > a:hover, .pagination ul > li > a:focus, .pagination ul > .active > a, .pagination ul > .active > span {
    outline: none;
}

.select2-container {
    text-align: left;
    font-size: 13px;
}

.select2-container .select2-choice {
    height: 25px !important;
}

.select2-container .select2-chosen {
    padding: 4px 6px !important;
}

.select2-container .select2-choice .select2-arrow b {
    background-position: 0 0 !important;
}

.col-total-row {
    white-space: nowrap;
}

.data-origin-container-body {
    overflow: auto;
    max-height: 685px;
}

.data-origin-container-body .BimIntegrationDataOrigin {
    overflow: unset;
    padding-right: 14px;
}

.domain-box-container {
    overflow-x: auto;
    white-space: nowrap;
    height: 395px;
}

.domain-box {
    display: inline-block;
    border: 1px solid #CCC;
    position: relative;
    padding: 20px 10px 10px;
    margin-top: 15px;
    vertical-align: top;
    width: 400px;
    height: 310px;

    margin-right: 5px;
}

.domain-box:last-child {
    margin-right: 0;
}


.domain-box .domain-label-container {
    position: absolute;
    top: -15px;
    text-align: center;
    width: 100%;
}

.domain-box .domain-label-container .domain-label {
    display: inline-block;
    padding: 5px;
    border: 1px solid #ccc;
    background-color: white;
}

.domain-box .domain-label-container .remove-domain,
.domain-box .domain-label-container .edit-domain {
    position: absolute;
    border-radius: 0;
    right: 10px;
    width: 22px;
    font-size: 10px;
    padding: 2px 0 2px 4px;
    top: 15px;
    left: auto;
}

.domain-box .domain-label-container .edit-domain {
    right: 33px;
}

.disabled .memberdiv {
    background-color: #EEE;
    cursor: not-allowed;
}

.NavSearchInput {
    width: 100%;
    border: 1px solid #ccc;
}
