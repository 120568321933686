.widget-box.projectBoxContent > .widget-header {
    height: 38px;
    border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
}

.ProjectTabs .widget-header:first-child {
    text-align: right;
}

.iceFrm .cleditorMain > iframe {
    background-color: white !important;
}

.BaseCsvSourceConfigFragment .widget-main {
    background-color: white;
}

.RemoteFileSourceFragment .BaseCsvSourceConfigFragment,
.RemoteFileSourceFragment .BaseCsvSourceConfigFragment .widget-box {
    box-shadow: none;
}

.RemoteFileSourceFragment .BaseCsvSourceConfigFragment .widget-header {
    padding: 15px 0 0 0;
    height: 25px;
    line-height: 25px;
}

.TomticketOriginDialog a .optionFBtype, .TomticketOriginDialog a:hover .optionFBtype {
    color: #555555;
}

.TomticketOriginDialog .fbchecktype {
    color: #02B046;
    display: none;
    margin-left: 4px;
}

.TomticketOriginDialog .optionFBtypeCheck .fbchecktype {
    display: block;
}

.TomticketOriginDialog .optionFBtype {
    filter: grayscale(90%);
}

.TomticketOriginDialog .optionFBtypeCheck {
    filter: grayscale(0);
}

.DialogJsf > .widget-header {
    border-bottom: 0;
}

.PipeDriveForm .widget-header {
    display: block;
    height: 40px;
}

.PipeDriveForm .widget-main-pipe .dialog-body {
    margin: 0;
}

.PipeDriveForm .widget-toolbar {
    display: inline-block;
    float: right
}

.linkhelp-type-object > a {
    background: transparent !important;
    color: #2876fd !important;;
}

.ExportCommentList .widget-header {
    border: none;
}

.ExportCommentList h4 {
    width: 100%;
    display: block;
}

#page-body-right .tab-content {
    height: calc(100vh - 170px);
}

#page-body-right-chart .tab-content {
    height: calc(100vh - 128px);
}

#page-body-right-chart .tabbable .tab-content > div {
    padding: 0px 14px;
}

.floating-object-actions.floating-object-actions-analysis {
    margin-top: -31px !important;
}

.projectBoxContent .widget-body .row-fluid {
    background: #fff;
}

.sidebar.menu-min.right-side-sidebar.open {
    width: 50px;
}

.page-content.right-menu-open {
    margin-right: 52px;
    overflow-x: hidden !important;
}

/*.DialogJsf.widget-box canvas.jqplot-series-shadowCanvas{*/
    /*background: #ffffff;*/
/*}*/

/*.kpi-sample-container canvas.jqplot-series-shadowCanvas {*/
    /*background: #f6f6f6;*/
/*}*/

.contentBox .itemdiv.memberdiv .span4.itemdiv .user {
    width: 38px;
    height: 38px;
    overflow: hidden;
}

.sidebar.menu-min.right-side-sidebar a.mobile-order-btn span.icon-tablet,
.sidebar.menu-min.right-side-sidebar a.mobile-order-btn span.icon-desktop{
    font-size: 20px !important;
}

ul.pull-right.dropdown-navbar.dropdown-menu.dropdown-caret.dropdown-close.lazy-init.dropdown-menu-file-recover {
    width: 320px;
}

button.btn.btn-primary.btn-link.btn-small.enable.btn-cockpit-manage-disable {
    color: #888888 !important;
}

.icePnlPopBody.dialogBody {
    overflow: hidden;
}

li.cpt-empty {
    border: 0;
    background: transparent;
}

.nav-notification-filters input[type=checkbox] + .lbl::before, .nav-notification-filters input[type=checkbox] + label::before{
    margin-top: -3px;
}

.notificationsTable .btn-new {
    background: transparent !important;
}

.dropdown-navbar.dropdown-menu > li.nav-header{
    border-radius: 4px 4px 0 0 !important;
}

.create-object-itens-container{
    max-height: calc(100vh - 84px);
    overflow: auto;
}

.manage-environment-itens-container{
    max-height: calc(100vh - 84px);
    overflow: auto;
}

.dropdown-menu > li.no-hover{
    border-radius: 0 0 4px 4px !important;
}

.cockpit-nav-btn i.icon-bim-book {
    font-size: 18px;
}

.resize-dropdown {
    max-height: calc(90vh - 90px);
    overflow: auto;
}

.home-folder-managment .widget-header,
#home-academy .widget-header,
.home-last-activities .widget-header,
.home-favorites .widget-header {
    border-bottom: 1px solid #E4E4E8;
}

li.dropdown-menu-item.cockpit-add-item-form-option a span {
    margin-left: 14px;
}

/*fix checkbox CSS Bootstrap*/
.radio input[type="radio"], .checkbox input[type="checkbox"] {
    margin-left: 0px !important;
}
/*bootstrap spinner button time new presentation*/

.input-append input+.btn-group .btn:last-child, .input-append select+.btn-group .btn:last-child, .input-append .uneditable-input+.btn-group .btn:last-child {
    border-radius: 0 0 4px 4px !important;
}

button.btn.spinner-down.btn-mini.btn-info,
button.btn.spinner-up.btn-mini.btn-info {
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 4px;
}

.tabs-left>.nav-tabs>li>a, .tabs-right>.nav-tabs>li>a {
    margin-bottom: 0 !important;
}

/*bootstrap color link*/
a:focus {
    color: unset;
}

/*nova tabela*/

.iceDatTblRow .crud-tableRow .crud-grid-formRow .crud-grid-row-odd, .crud-grid-row-odd td,
.iceDatTblRow .crud-tableRow .crud-grid-formRow .crud-grid-row-even, .crud-grid-row-even td {
    border-right: 0 !important;
    border-left: 0 !important;
}

button span.icon-bim-filter:before {
    vertical-align: middle;
    margin-top: -2px;
}

li.dropdown-menu-item-content .monitoring-content{
    text-align: left;
}

/*ajustes tabela de origins*/
.tableDataOrigins th:nth-child(8) div{
    text-align: center !important;
}

.tableDataOrigins td.iceDatTblCol, .tableDataOrigins th, .tableDataOrigins th div{
    text-align: left !important;
}

.tableDataOrigins td.iceDatTblCol:nth-child(2), .tableDataOrigins th:nth-child(2) {
    min-width: 70px;
}

.tableDataOrigins td.iceDatTblCol:nth-child(3), .tableDataOrigins th:nth-child(3) {
    min-width: 50px;
    max-width: 100px;
}

.tableDataOrigins td.iceDatTblCol:nth-child(6), .tableDataOrigins th:nth-child(6) {
    min-width: 90px;
    max-width: 148px;
}

.tableDataOrigins td.iceDatTblCol:nth-child(7), .tableDataOrigins th:nth-child(7) {
    min-width: 70px;
    max-width: 90px;
}

.tableDataOrigins td.iceDatTblCol:nth-child(8), .tableDataOrigins th:nth-child(8) {
    width: 18px;
}

.tableDataOrigins td.iceDatTblCol:nth-child(9), .tableDataOrigins th:nth-child(9) {
    width: 250px;
}

/*ajustes na tabela de medidas calculadas*/

.calculated-measures-table {
    position: relative;
    overflow: hidden;
}

.calculated-measures-table td.iceDatTblCol.crud-tableCol.crud-grid-formCol:nth-child(4),
.calculated-measures-table th.iceDatTblColHdr2.crud-tableColHdr2.crud-grid-formColHdr2.crud-tableHdr.crud-grid-formHdr:nth-child(4),
.calculated-measures-table td.iceDatTblCol.crud-tableCol.crud-grid-formCol:nth-child(5),
.calculated-measures-table th.iceDatTblColHdr2.crud-tableColHdr2.crud-grid-formColHdr2.crud-tableHdr.crud-grid-formHdr:nth-child(5){
    width: 60px;
}

.calculated-measures-table td.iceDatTblCol.crud-tableCol.crud-grid-formCol:nth-child(1),
.calculated-measures-table th.iceDatTblColHdr2.crud-tableColHdr2.crud-grid-formColHdr2.crud-tableHdr.crud-grid-formHdr:nth-child(1){
    width: 80px;
}

.calculated-measures-table td.iceDatTblCol.crud-tableCol.crud-grid-formCol:nth-child(6),
.calculated-measures-table th.iceDatTblColHdr2.crud-tableColHdr2.crud-grid-formColHdr2.crud-tableHdr.crud-grid-formHdr:nth-child(6){
    width: 140px;
}


/*ajustes na tabela de filtros*/

.manage-filters-table td.iceDatTblCol, .manage-filters-table th{
    text-align: left;
}

.manage-filters-table td.iceDatTblCol:nth-child(1), .manage-filters-table th:nth-child(1){
    min-width: 54px;
}

.manage-filters-table td.iceDatTblCol:nth-child(4), .manage-filters-table th:nth-child(4){
    width: 70px;
}

.manage-filters-table td.iceDatTblCol:nth-child(5), .manage-filters-table th:nth-child(5){
    width: 130px;
}

.manage-filters-table td.iceDatTblCol:nth-child(6), .manage-filters-table th:nth-child(6){
    width: 100px;
}


/*ajustes na tabela de filtros de usuarios*/

.user-filters-table-container td.iceDatTblCol, .user-filters-table-container th{
    text-align: left;
}

.user-filters-table-container td.iceDatTblCol:nth-child(1), .user-filters-table-container th:nth-child(1),
.user-filters-table-container td.iceDatTblCol:nth-child(4), .user-filters-table-container th:nth-child(4){
    width: 60px;
}

